<template>
  <!-- 宜享花专用进件页面 -->
  <div class="container" ref="container" v-cloak>
    <!-- 顶部借款金额 -->
    <div class="container-top">
      <div class="u-flex u-row-between">
        <div class="u-flex u-col-top">
          <img src="../assets/images/icon_check_mark.png" />
          <div class="u-flex-col">
            <p class="container-top-title">借款订单已生成</p>
            <span>首次借款需完成以下信息补充</span>
          </div>
        </div>
        <div class="u-flex-col u-text-right">
          <p>借款金额(元)</p>
          <h4 class="container-top-amount">{{ form.loanAmount }}</h4>
        </div>
      </div>
    </div>

    <!-- 注册表单 -->
    <div class="register-form">
      <!-- 真实姓名 -->
      <div class="register-form-item mb0" ref="realName">
        <van-cell-group>
          <van-field
            label="真实姓名"
            v-model.trim="form.realName"
            class="inp-elem"
            placeholder="请输入您的真实姓名(已加密)"
            @blur="handleBlur(0)"
            clearable
            input-align="right"
          />
        </van-cell-group>
      </div>

      <!-- 身份证号 -->
      <div class="register-form-item" ref="idCard">
        <van-cell-group>
          <van-field
            label="身份证号码"
            v-model.trim="form.idCard"
            maxlength="18"
            class="inp-elem"
            placeholder="请输入您的身份证号(已加密)"
            @blur="handleBlur(1)"
            clearable
            input-align="right"
          />
        </van-cell-group>
      </div>

      <!-- 芝麻信用分 -->
      <div class="register-form-item border-none" ref="credit">
        <h4
          style="
            font-size: 0.28rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #111c31;
          "
        >
          芝麻信用分
        </h4>
        <div class="inp-elem tags">
          <van-row :gutter="5">
            <van-col
              v-for="(item, index) in formItemData.credit"
              :key="index"
              :class="item.active ? 'active' : ''"
              span="8"
              @click="handleChooseCreditCredit(index)"
            >
              <div class="u-flex u-col-center u-row-center">
                {{ item.dictLabel }}
              </div>
            </van-col>
          </van-row>
        </div>
      </div>

      <!-- 资产信息(可多选) -->
      <div class="register-form-item mb0">
        <h4
          style="
            font-size: 0.28rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #111c31;
          "
        >
          资产信息(可多选)
        </h4>
        <div class="inp-elem tags">
          <van-row :gutter="5">
            <van-col
              v-for="(item, index) in formItemData.creditType"
              :key="index"
              :class="item.active ? 'active' : ''"
              span="8"
              @click="handleSelectCreditInfo(index)"
            >
              <div class="u-flex u-col-center u-row-center">
                {{ item.dictLabel }}
              </div>
            </van-col>
          </van-row>
        </div>
      </div>

      <div class="register-form-item">
        <!-- 蚂蚁花呗 -->
        <div class="u-flex" style="border-bottom: 0.02rem solid #f5f5f5">
          <h4
            style="
              font-size: 0.28rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              color: #111c31;
            "
          >
            蚂蚁花呗
          </h4>
          <div class="inp-elem tags u-flex-1">
            <van-row class="u-flex u-row-right" :gutter="8">
              <van-col
                v-for="(item, index) in formItemData.antCreditPay"
                :key="index"
                :class="item.active ? 'active' : ''"
                span="6"
                @click="handleChooseCreditAntCreditPay(index, false)"
              >
                <div class="u-flex u-row-center mt0">
                  {{ item.dictLabel }}
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
        <!-- 京东白条 -->
        <div class="u-flex">
          <h4
            style="
              font-size: 0.28rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              color: #111c31;
            "
          >
            京东白条
          </h4>
          <div class="inp-elem tags u-flex-1">
            <van-row class="u-flex u-row-right" :gutter="8">
              <van-col
                v-for="(item, index) in formItemData.jdIous"
                :key="index"
                :class="item.active ? 'active' : ''"
                span="6"
                @click="handleChooseCreditJdIous(index, false)"
              >
                <div class="u-flex u-row-center mt0">
                  {{ item.dictLabel }}
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>

      <!-- 贷款申请城市 -->
      <div class="register-form-item">
        <van-cell-group>
          <van-field
            label="贷款申请城市"
            v-model="addressPositionName"
            readonly
            class="inp-elem"
            input-align="right"
            :border="false"
          >
            <template #button>
              <span @click="handleClickPosition">修改</span>
            </template>
          </van-field>
          <p class="citys-tips">
            准确的贷款申请城市将决定我们是否能为您提供贷款服务，请按您所在地如实填写
          </p>
        </van-cell-group>
      </div>
    </div>

    <!-- 确认额度 -->
    <div class="btn-con">
      <van-button
        class="btn"
        type="default"
        @click="handleSubmit"
        :loading="loading"
        loading-type="spinner"
      >
        确认额度
      </van-button>
    </div>

    <!-- 协议 -->
    <van-checkbox
      v-model="checked"
      checked-color="#3563FA"
      icon-size="0.3rem"
      class="protocols u-flex u-col-top"
    >
      我已阅读并同意
      <small @click.stop="showProp('xxsq')" class="protocols-title">
        《个人信息共享授权书》</small
      >
      <small @click.stop="showProp('zqgz')" class="protocols-title">
        《知情告知书》
      </small>
    </van-checkbox>

    <!-- 热线电话 -->
    <p class="tel-con u-text-center">
      <van-icon size="0.22rem" color="#E71A0F" name="phone-o" />
      <a href="tel:4008818897">客服热线: 400-881-8897</a>
    </p>

    <!-- 底部风险提示 -->
    <div class="bottom u-text-center">
      贷款有风险，借款需谨慎<br />
      综合年化6%-24%，最终结果以审批为准<br />
      请根据个人能力合理贷款，理性消费<br />
    </div>

    <!-- 定位城市下拉选 -->
    <van-popup
      v-model="showPickerCity"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        show-toolbar
        :columns="areaList"
        @change="onCityChange"
        @confirm="onAreaConfirm"
        @cancel="showPickerCity = false"
        value-key="localName"
      >
        <div slot="title" class="picker-title u-text-center">
          <p>请选择定位城市</p>
          <p class="pa">
            准确的贷款申请城市将决定我们是否能为您提供贷款服务，请按您所在地如实填写
          </p>
        </div>
      </van-picker>
    </van-popup>

    <!-- 提交进件信息注册的我已知晓下一步的弹窗 -->
    <Agreement
      @handleGoOn="handleGoOn"
      :incomingShow="incomingShow"
      :sendData="sendData"
      :form="form"
      :applyTime="applyTime"
      :companys="companys"
      :companyAlias="companyAlias"
      :productId="productId"
      :applyId="applyId"
      :source="2"
    />

    <!-- 《个人信息共享授权书》和《知情告知书》局部弹窗 -->
    <van-popup
      v-model="showProtocol"
      round
      :style="{
        width: '80%',
        height: '70%',
      }"
      @close="handleClose"
    >
      <div class="show-protocol-con">
        <h4 class="u-text-center">{{ loaclTitle }}</h4>
        <div class="show-protocol-info protocol-content" v-html="showContent" />
        <div
          class="show-protocol-btn u-text-center"
          @click="showProtocol = false"
        >
          关闭
        </div>
      </div>
    </van-popup>

    <!-- 退出阻断弹窗 -->
    <van-popup
      v-model="returnShow"
      :close-on-click-overlay="false"
      :style="{
        width: '80%',
        height: 'auto',
      }"
      round
    >
      <div class="return-con u-text-center">
        <h3>
          距离<span>{{ this.form.loanAmount }}</span
          >元额度
        </h3>
        <h1>仅剩1步</h1>
        <p class="return-con-tips">确定要离开吗？</p>
        <van-button
          class="return-con-btn"
          block
          color="#3563fa"
          round
          @click="returnShow = false"
        >
          继续填写
        </van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
// import "@/assets/styles/registerEntry_tl4.scss";
import * as API_Common from "@/api/common.js";
import Agreement from "../components/Agreement";
import { applyCommon } from "@/mixins/applyCommon.js";
import { mapPosition } from "@/mixins/mapPosition.js";
import { applyBus } from "@/mixins/applyBusEntry.js";

export default {
  name: "RegisterEntryTl4",
  mixins: [applyCommon, mapPosition, applyBus],
  data() {
    return {
      positionStatus: false, // 定位是否成功，默认false
      positionCityStatus: false, // 百度定位中是否包含城市信息(如果不包含也相当于定位失败)
      provinceArr: undefined, // 百度定位中是否包含城市信息(如果不包含也相当于定位失败)-集合中是对应省的一条数据
      checked: false, // 协议勾选
      address: {
        // 定位地址
        province: "", // 省
        city: "", // 市
      },
      areaList: [
        // 自定义省数据二级结构
        { values: [] },
        { values: [] },
      ],
      showPickerCity: false, // 省市联动弹窗状态
      form: {
        // 提交表单
        channelNo: this.$route.query.source, // 渠道标识
        loanAmount: this.$route.query.amount, // 借款金额
        loanPeriod:
          parseInt(this.$route.query.period) === 9
            ? 12
            : parseInt(this.$route.query.period), // 借款期限(宜享花传入的期限可能为9期，为了兼容咱们自己的借款期限(目前支持3 6 12 24)的情况如果宜享花传入的期数为9强制修改为12期 )
        loanPurpose: this.$route.query.purpose, // 借款用途
        realName: "", // 姓名
        idCard: "", // 身份证号码
        credit: "", // 芝麻分
        antCreditPay: "", // 蚂蚁花呗
        jdIous: "", // 京东分
        assets: [], // 个人资产
        gpsCityName: "", // 省市展示名称
        cityId: "", // 城市ID
        provinceId: "", // 省ID
      },
      incomingShow: false, // 提交进件信息弹窗
      returnShow: false, // 退出阻断弹窗状态
      xxsq: "", // 个人信息共享授权书
      zqgz: "", // 知情告知书
      showContent: "", //  // 个人信息共享授权书 和 知情告知书 局部弹窗详细内容
      showProtocol: false, // 个人信息共享授权书 和 知情告知书 局部弹窗隐藏显示
      tdArea: "", // 特定机构的地理位置信息
      isSpecificInstitution: false, //是否需要展示特定机构的协议
      companys: "", // 公司名称集合
      companyAlias: "", // 公司别名
      loaclTitle: "", // 局部协议弹窗顶部title,
      brand: this.$route.query.brand, // 设备型号
      downPageUrl: this.$route.query.downPageUrl, // 进件结果下载页地址
      applyId: "", // 进件申请ID
      applyTime: "", // 进件申请时间
      hasPushSuccess: undefined, // 是否推送成功
      jwt: this.$route.query.jwt, // 登录字符串
      env: process.env.NODE_ENV, // 是否是线上环境
      loading: false, //确认额度按钮点击状态
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程的字段1是 0否
      isRedirectWorkWeixinPage: parseInt(
        this.$route.query.isRedirectWorkWeixinPage
      ), // 标识如果不是贷超流程需要用此字段进行判断是否跳转引导企业微信页面中，引导用户用企业微信扫码联系客服1是 0否
      workWeixinUrl: this.$route.query.workWeixinUrl, // 跳转微信地址url
      redirectUrl: "", // 重定向链接
      productId: null, // 产品id
      sendData: null,
    };
  },
  components: {
    Agreement,
  },
  mounted() {
    this.addHistory(); // 添加浏览器浏览记录
    this.checkPosition(); // 获取是否支持定位
    this.getProtocol(); // 获取个人信息共享授权书 和 知情告知书 协议内容
    // vuex中存储登录状态
    this.$nextTick(() => {
      this.$store.dispatch("setJwt", this.jwt);
      this.handleLoadFinish(4);
    });
  },
  destroyed() {
    // 页面销毁时必须要移除这个事件，vue 不刷新页面，不移除会重复执行
    window.removeEventListener("popstate", this.openReturnPop, false);
  },
  watch: {
    //弹框监听，当弹框显示的时候，pushState 添加一个历史，供返回键使用
    returnShow(newVal) {
      if (newVal === true) {
        window.history.pushState(null, null, document.URL);
      }
    },
  },
  computed: {
    addressPositionName() {
      // 顶部定位名称
      if (this.positionStatus) {
        return `${this.address.province} ${this.address.city}`;
      } else {
        if (this.form.gpsCityName) {
          return this.form.gpsCityName;
        } else {
          return "定位失败";
        }
      }
    },
  },
  methods: {
    // 监听表单blur
    handleBlur(index) {
      switch (index) {
        case 0: // 姓名输入框失去光标
          if (this.form.realName === "") {
            this.$toast("请输入正确的姓名");
          }
          break;
        case 1: // 身份证输入框失去光标
          if (this.form.idCard !== "") {
            if (!this.regTest.regIdcard.test(this.form.idCard)) {
              this.$toast("请输入正确的身份证号码");
            }
          }
          break;
      }
    },

    // 显示协议
    showProp(type) {
      this.showContent = this[type];
      this.showProtocol = true;
      this.loaclTitle =
        type === "zqgz" ? "《知情告知书》" : "《个人信息共享授权书》";
    },

    // 弹窗关闭时触发
    handleClose() {
      this.showContent = "";
      this.loaclTitle = "";
    },

    // 获取个人信息共享授权书 和 知情告知书 协议内容
    getProtocol() {
      Promise.all([API_Common.getContent(13), API_Common.getContent(14)]).then(
        (res) => {
          this.xxsq = res[0].data.content;
          this.zqgz = res[1].data.content;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/styles/registerEntry_tl4.scss"></style>
